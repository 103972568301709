<template>
  <v-main class="login">
    <div class="px-3 mt-10">
      <v-row class="">
        <v-col cols="12" sm="6" class="pb-0">
          <p class="subtitle-2 font-weight-black ma-0">ログインID（メールアドレス）</p>
          <v-text-field v-model="login_id" single-line outlined dense @change="inputLoginId"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="pb-0">
          <p class="subtitle-2 font-weight-black ma-0">パスワード</p>
          <v-text-field v-model="password" single-line outlined dense
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'" @click:append="show = !show" @change="inputPassword">
          </v-text-field>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-btn rounded class="maincolor-bg default_button"
          @click.stop="login()">ログイン</v-btn>
      </div>

      <v-divider class="my-5"></v-divider>

      <div v-if="password_integration_status !== 1">
        <v-row class="caption">
          <v-col cols="6" class="text-right">
            <a @click.stop="lostPassword()">パスワードをお忘れの方</a>
          </v-col>
          <v-col cols="6" class="text-left">
            <a @click.stop="newAccount()">新しいアカウントを作る</a>
          </v-col>
        </v-row>
      </div>

      <v-divider class="my-5"></v-divider>

      <div class="text-center caption pt-10">
        <p class="">
          本アプリをご利用の際には、
          <router-link to="/info/rule">利用規約</router-link>
          をご確認ください。
        </p>
        <p class="caption" v-if="init.inquiry_mail">
          <v-icon>far fa-envelope</v-icon>
          <a :href="'mailto:'+init.inquiry_mail">お問い合わせ</a>
        </p>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      login_id: null,
      password: "",

      init: this.storageGet('*'),
      loading: false,
      show: false,
      password_integration_status: null,
    };
  },
  created() {
    this.password_integration_status = this.init.password_integration_status
  },
  mounted () {
    const json = this.storageSessionGet('*');
    if( !json ) {
      this.storageSessionSave(JSON.stringify({}));
    } else {
      const loginId = this.storageSessionGet("input_login_id");
      const password = this.storageSessionGet("input_password");
      if(loginId) {
        this.login_id = loginId;
      }
      if(password) {
        this.password = password;
      }
    }
  },
  watch: {
      //init apiが読み込まれたか監視
    // '$root.init_flg': 'fetchData',
  },
  methods: {
    async lostPassword() {
      const callback = encodeURIComponent(process.env.VUE_APP_BASE_URL + '/login')
      this.showEcPage('/password.html', 'callback=' + callback)
    },
    async newAccount() {
      await this.$router.push({name:'register_email'})
    },
    async login() {
      try {
        //loadingを表示
        this.loading = true;

        const login_req = {
          'type'    : 'App',
          'mail'    : this.login_id,
          'password': this.password,
        };
        const login_res = await this.apiCallCustomerPF('/auth_code/login', login_req);
        this.storageSave(JSON.stringify({
          'login_id'   : this.login_id,
          'device_uid' : login_res.yamaya_id,
        }));

        await this.$router.push({name: 'check_authcode', query: {scene: 'login'}});

      } catch(e) {
        console.log(e);
        this.loading = false
        this.callDialog('ログインエラー', 'ログインID（メールアドレス）もしくはパスワードを確認してください。', 3);
      }
    },
    async inputLoginId(e) {
      this.storageSessionSave(JSON.stringify({
        "input_login_id"  : e ,
      }));
    },
    async inputPassword(e) {
      this.storageSessionSave(JSON.stringify({
        "input_password"  : e ,
      }));
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}
</style>
